import { PageProps } from "gatsby";
import * as React from "react";

import SEO from "~/components/layout/Seo";

interface ErrorPageProps extends PageProps {}
const ErrorPage: React.FC<ErrorPageProps> = (props) => {

  return (
    <>
      <SEO title="" />
      <h1>404</h1>
    </>
  )
}
export default ErrorPage;